export const COLORS = {
    white: "#F4F4F4",
    darkGreen: "#1D4F3A",
    lightGreen: "#A1AC7B",
    orange: "#D0953B",
    lightBlue: "#B0BAD5",
    yellow: "#F4C443",
};

export function getClosedNavItemSize(isVert: boolean) {
    return {
        CLOSED_ITEM_SIZE_BEFORE: isVert ? 5 : 2.275,
        CLOSED_ITEM_SIZE_AFTER: isVert ? 5 : 3.5,
        CLOSED_ITEM_EXTRA: isVert ? 3 : 0,
    }
}
