import { useEffect } from "react";

import { PageId } from "../components/Navigation";
import { getWidth, getIsVertical } from "./getIsVertical";
import { getClosedNavItemSize } from "./consts";

function onResize(
    currentPageId: PageId,
    onChangeCallback: (isVert: boolean) => void,
) {
    const width = getWidth();
    // On 1920px width the font size is 135%, minimum is 50%
    document.documentElement.style.fontSize = `${Math.max(width * 135 / 1920, 50)}%`;

    const isPortrait = getIsVertical();
    const hasVerticalClass = document.body.classList.contains("vertical");

    if (isPortrait && !hasVerticalClass) {
        document.body.classList.add("vertical");
        onChangeCallback(true);
        addBodyPaddings(currentPageId, true);
    } else if (!isPortrait && hasVerticalClass) {
        document.body.classList.remove("vertical");
        onChangeCallback(false);
        addBodyPaddings(currentPageId, false);
    }
}

export function useFontSizeResize(
    currentPageId: PageId,
    onChangeCallback: (isVert: boolean) => void,
) {
    useEffect(() => {
        let timeoutId: NodeJS.Timeout | null = null;
        onResize(currentPageId, onChangeCallback);

        const resizeListener = () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                onResize(currentPageId, onChangeCallback);
            }, 150);
        };

        window.addEventListener("resize", resizeListener);

        return () => {
            window.removeEventListener("resize", resizeListener);
        }
    }, []);
}

export function addBodyPaddings(currentPageId: PageId, isVert: boolean) {
    const {
        CLOSED_ITEM_SIZE_BEFORE,
        CLOSED_ITEM_SIZE_AFTER,
        CLOSED_ITEM_EXTRA,
    } = getClosedNavItemSize(isVert);

    const beforePadding = currentPageId * CLOSED_ITEM_SIZE_BEFORE;
    const numberOfPages = Object.keys(PageId).length / 2;
    const afterPadding = (numberOfPages - currentPageId - 1) * CLOSED_ITEM_SIZE_AFTER + CLOSED_ITEM_EXTRA;

    document.body.style.paddingTop = isVert ? `${beforePadding}rem` : "";
    document.body.style.paddingBottom = isVert ? `${afterPadding}rem` : "";
    document.body.style.paddingLeft = isVert ? "" : `${beforePadding}rem`;
    document.body.style.paddingRight = isVert ? "" : `${afterPadding}rem`;
}
