import { Link, useI18next } from "gatsby-plugin-react-i18next";
import * as React from "react";

import "./LanguageSwitcher.css";

export const LanguageSwitcher: React.FC = () => {
    const { languages, originalPath, i18n } = useI18next();

    return (
        <div className="languageSwitcher">
            <ul className="languageList">
                {languages.filter(lng => lng !== i18n.resolvedLanguage).map(lng => (
                    <li key={lng}>
                        <Link
                            to={originalPath}
                            language={lng}
                        >
                            {lng.toUpperCase()}
                        </Link>
                    </li>
                ))}
                <li key={i18n.resolvedLanguage} style={{ fontWeight: "bolder" }}>
                    <a>{i18n.resolvedLanguage?.toUpperCase()}</a>
                </li>
            </ul>
        </div>
    );
}
