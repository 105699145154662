export const getWidth = () => window.innerWidth 
    || document.documentElement.clientWidth 
    || document.body.clientWidth;

export const getHeight = () => window.innerHeight 
    || document.documentElement.clientHeight 
    || document.body.clientHeight;

export function getIsVertical() {
    if (typeof window === "undefined") {
        return false;
    }

    const width = getWidth();
    const height = getHeight();

    return width < height;
}