import * as React from "react";

import Navigation, { PageId } from "./Navigation";
import { useFontSizeResize, addBodyPaddings } from "../utils/useFontSizeResize";
import { getIsVertical } from "../utils/getIsVertical";
import { COLORS } from "../utils/consts";

import "./Layout.css";
import "./common.css";

export interface LayoutType {
	pageId: PageId,
	resizeCallback?: () => void,
}

const Layout: React.FC<LayoutType> = ({ pageId, resizeCallback, children }) => {
	const [isVertical, setIsVertical] = React.useState(false);

	useFontSizeResize(pageId, isVert => {
		setIsVertical(isVert);
		resizeCallback?.();
	});

	React.useEffect(() => {
		const isVert = getIsVertical();
		addBodyPaddings(pageId, isVert);
		setIsVertical(isVert);

		// Set global CSS variables
		document.documentElement.style.setProperty("--color-white", COLORS.white);
		document.documentElement.style.setProperty("--color-dark-green", COLORS.darkGreen);
		document.documentElement.style.setProperty("--color-light-green", COLORS.lightGreen);
		document.documentElement.style.setProperty("--color-orange", COLORS.orange);
		document.documentElement.style.setProperty("--color-light-blue", COLORS.lightBlue);
		document.documentElement.style.setProperty("--color-yellow", COLORS.yellow);
	}, []);

	return (
		<>
			<main
				style={{
					position: "relative",
					width: "100%",
					height: "100%",
					overflow: "hidden",
				}}
				id="main-wrapper"
				key={isVertical ? "vertical" : "horizontal"}
			>
				{children}
			</main>
			<Navigation currentPageId={pageId} isVertical={isVertical} />
		</>
	);
}

export default Layout;
